var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isSelectAll || _vm.selectedItem.length ? _c('div', {
    staticClass: "d-flex align-items-end justify-content-start",
    staticStyle: {
      "gap": "10px"
    }
  }, [_vm.roleID !== 5 ? _c('b-button', {
    attrs: {
      "variant": "outline-danger",
      "disabled": _vm.isLoadingUnPublish
    },
    on: {
      "click": _vm.onSubmitUnPublishSelected
    }
  }, [_vm.isLoadingUnPublish ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('em', {
    staticClass: "fa fa-eye-slash"
  }), _vm._v(" UnPublished ")], 1) : _vm._e(), _vm.roleID !== 5 ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.selectedItem.length) + " selected item")]) : _vm._e()], 1) : _vm._e(), _c('b-table', {
    ref: "tableList",
    attrs: {
      "sticky-header": "600px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "hover": "",
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.books,
      "selectable": ""
    },
    on: {
      "row-selected": _vm.onRowSelected
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "head(no)",
      fn: function (_ref) {
        var label = _ref.label;
        return [_vm.roleID !== 5 ? _c('b-form-checkbox', {
          staticClass: "text-nowrap text-right",
          staticStyle: {
            "display": "table-cell"
          },
          on: {
            "input": _vm.onSelectAllRows
          },
          model: {
            value: _vm.isSelectAll,
            callback: function ($$v) {
              _vm.isSelectAll = $$v;
            },
            expression: "isSelectAll"
          }
        }, [_vm._v(" " + _vm._s(label) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(" " + _vm._s(row.rowSelected ? '✓' : '') + " " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")])];
      }
    }, {
      key: "cell(title)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "300px"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.DetailItems.name,
              params: {
                id: row.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline"
          },
          on: {
            "click": function ($event) {
              return _vm.copyTextToClipboard(row.item.id);
            }
          }
        }, [_c('em', {
          staticClass: "fa fa-clipboard"
        }, [_vm._v(" " + _vm._s(row.item.id) + " ")])])], 1)];
      }
    }, {
      key: "cell(printed_price)",
      fn: function (row) {
        return [row.item.printed_price && row.item.printed_currency_code ? _c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.printed_price, row.item.printed_currency_code)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(item_status)",
      fn: function (row) {
        return [row.item.item_status == _vm.itemStatus.Unpublished ? _c('span', {
          staticClass: "badge badge-danger p-1"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")]) : row.item.item_status == _vm.itemStatus.Published ? _c('span', {
          staticClass: "badge badge-primary p-1"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")]) : row.item.item_status == _vm.itemStatus.Uploaded ? _c('span', {
          staticClass: "badge badge-warning p-1"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")]) : _c('span', {
          staticClass: "badge badge-info p-1"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")])];
      }
    }, {
      key: "cell(size)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertMegaBite(row.item.file_size)) + " ")])];
      }
    }, {
      key: "cell(action_ofs)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-default",
          on: {
            "click": function ($event) {
              return _vm.actionSendOfs(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-sign-in"
        })])])];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('b-dropdown', {
          staticClass: "m-2 position-static",
          attrs: {
            "split": "",
            "split-variant": "outline-primary",
            "variant": "primary",
            "text": "Actions"
          }
        }, [_vm.roleID !== 5 ? _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.actionUpdateStatus(row.item, 'publish', _vm.status.Published);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-eye"
        }), _vm._v(" Published ")]) : _vm._e(), _vm.roleID !== 5 ? _c('b-dropdown-item', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.actionUpdateStatus(row.item, 'unpublish', _vm.status.Unpublished);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-eye-slash"
        }), _vm._v(" UnPublished ")]) : _vm._e(), row.item.item_type != _vm.item_types_audio ? _c('div', [_vm.permBtnMcGraw() ? _c('b-dropdown-item', {
          attrs: {
            "variant": "black"
          },
          on: {
            "click": function ($event) {
              return _vm.actionUpdateStatus(row.item, 'mcgrawhill content', _vm.status.UnpublishedMcgrawhill);
            }
          }
        }, [_vm._v(" mcgrawhill content ")]) : _vm._e()], 1) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-item', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.actionEdit(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil"
        }), _vm._v(" Edit Items ")]), row.item.item_type == _vm.item_types_audio ? _c('div', [_c('b-dropdown-item', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.actionEdit({
                id: row.item.id,
                item_type: 'Chapter'
              });
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil"
        }), _vm._v(" Edit Chapter ")])], 1) : _vm._e(), !_vm.ownVendor.id ? _c('div', [row.item.item_type == 'book' ? _c('b-dropdown-item', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.actionSendAx(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-send"
        }), _vm._v(" Register to AX ")]) : _vm._e(), row.item.item_type == 'book' ? _c('b-dropdown-item', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.actionSendAxPremium(row.item.id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-send"
        }), _vm._v(" Register to AX (PREMIUM) ")]) : _vm._e()], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(vendor_name)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.vendor : '') + " ")])];
      }
    }, {
      key: "cell(action_premium)",
      fn: function () {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-default"
        }, [_c('i', {
          staticClass: "fa fa-ellipsis-v",
          attrs: {
            "aria-hidden": "true"
          }
        })])])];
      },
      proxy: true
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-modal', {
    attrs: {
      "hide-footer": true,
      "size": "md"
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-md-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("ID AX")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.id_ax,
      expression: "Form.id_ax"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": ""
    },
    domProps: {
      "value": _vm.Form.id_ax
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.Form, "id_ax", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-success",
    on: {
      "click": _vm.actionSend
    }
  }, [_c('i', {
    staticClass: "fa fa-sign-in"
  }, [_vm._v(" Send OFS ")])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }